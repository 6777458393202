var CLIENT_IP, FINGERPRINT, STATE, jQuery;

STATE = null;

FINGERPRINT = null;

CLIENT_IP = null;

History.Adapter.bind(window, 'statechange', function() {
  return STATE = History.getState();
});

jQuery = $;

STATE = History.getState();

$(document).ready(function() {
  if (typeof sessionStorage.getItem('ip') === "undefined") {
    sessionStorage.setItem('ip', $(".table-torrents").data('clientip'));
  }
  if (typeof sessionStorage.getItem('ip') === "undefined") {
    sessionStorage.setItem('ip', $(".table-torrents").data('clientip'));
  }
  window.scrollTo(0, 0);
  return $('.banner .banner-btn').on("click", function(e) {
    e.preventDefault();
    return window.open("https://www.bitlord.com/protect-your-privacy-when-downloading-or-streaming-by-using-a-vpn-service/", '_blank');
  });
});
